import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";

import ContentRoot from "@/pages/layouts/content-root";
import RequireAuth from "@/pages/layouts/require-auth";
import Root from "@/pages/layouts/root";

import Account from "@/pages/account";
import AccountEmailVerification from "@/pages/account/email-verification";
import AccountPasswordReset from "@/pages/account/password-reset";
import AccountPasswordResetEdit from "@/pages/account/password-reset/edit";
import NotFound from "@/pages/errors/not-found";
import Home from "@/pages/home";
import MyWishlists from "@/pages/my-wishlists";
import PurchasedIdeas from "@/pages/purchased-ideas";
import SharedWishlists from "@/pages/shared-wishlists";
import SignIn from "@/pages/sign-in";
// import SignUp from "../routes/sign-up";

const root = document.getElementById("root");

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route path="sign_in" element={<SignIn />} />
      {/* <Route path="sign_up" element={<SignUp />} /> */}
      <Route path="account/email_verification" element={<AccountEmailVerification />} />
      <Route path="account/password_reset" element={<AccountPasswordReset />} />
      <Route path="account/password_reset/edit" element={<AccountPasswordResetEdit />} />
      <Route element={<ContentRoot />}>
        <Route index={true} element={<Home />} />
        <Route element={<RequireAuth />}>
          <Route path="account" element={<Account />} />
          <Route path="my_wishlists/:wishlistId?" element={<MyWishlists />} />
          <Route path="shared_wishlists/:wishlistId?" element={<SharedWishlists />} />
          <Route path="purchased_ideas" element={<PurchasedIdeas />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const queryClient = new QueryClient();

if (root) {
  createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </StrictMode>
  );
} else {
  console.error("WARNING: root element not found.");
}
