import { Box } from "@mui/material";
import React from "react";

import useAppContext from "@/hooks/useAppContext";
import { useWishlistIdParam } from "@/hooks/useWishlists";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";

import WishlistTabs from "@/components/WishlistTabs";
import BlankSlate from "./BlankSlate";
import List from "./List";
import Show from "./Show";
import Sidebar from "./Sidebar";

type WishlistListProps = {
  category: WishlistCategory;
  wishlists: WishlistRecord[];
};

const WishlistList = ({ category, wishlists }: WishlistListProps) => {
  const wishlistId = useWishlistIdParam();
  const { isMobile } = useAppContext();
  const hasWishlists = wishlists.length > 0;

  return (
    <>
      <WishlistTabs category={category} />
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        {!isMobile && wishlists.length > 0 && <Sidebar category={category} wishlists={wishlists} />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            pl: { md: 4 }
          }}
        >
          {wishlistId && <Show category={category} wishlistId={wishlistId} />}
          {!wishlistId && isMobile && <List category={category} wishlists={wishlists} />}
          {!wishlistId && !isMobile && (
            <BlankSlate category={category} hasWishlists={hasWishlists} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default WishlistList;
