import { useMutation, useQueryClient } from "@tanstack/react-query";

import hasQueryData from "@/lib/hasQueryData";
import type { IdeaRecord } from "@/types/ideas";
import { UserRecord } from "@/types/users";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";
import { useOnIdeaUpdate } from "./useIdeas";
import useRestApi from "./useRestApi";
export { useWishlistIdParam } from "./useWishlists";

export const isPurchased = (idea: IdeaRecord) => {
  return !!idea.purchasedAt;
};

export const isPurchasedByUser = (idea: IdeaRecord, user: UserRecord | undefined) => {
  return isPurchased(idea) && idea.purchasedById === user?.id;
};

export const usePurchasesApi = () => {
  return useRestApi<IdeaRecord>(`/api/purchases`);
};

export const getPurchasedIdeasQueryKey = () => {
  return ["purchased_ideas"];
};

export const useIndexPurchasedIdeasQuery = () => {
  const api = usePurchasesApi();
  return {
    queryKey: getPurchasedIdeasQueryKey(),
    queryFn: async () => {
      const response = await api.index();
      return response.data;
    }
  };
};

export const useOnPurchaseIdea = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  const queryClient = useQueryClient();
  const onIdeaUpdate = useOnIdeaUpdate(category, wishlistId);

  return (purchasedIdea: IdeaRecord) => {
    onIdeaUpdate(purchasedIdea);

    const queryKey = getPurchasedIdeasQueryKey();
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (purchasedIdeas: IdeaRecord[]) => {
        return [purchasedIdea, ...purchasedIdeas];
      });
    }
  };
};

export const usePurchaseIdeaMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = usePurchasesApi();
  const onPurchaseIdea = useOnPurchaseIdea(category, wishlistId);

  return useMutation({
    mutationFn: async ({ ideaId }: { ideaId: IdeaRecord["id"] }) => {
      const response = await api.create({ id: ideaId });
      return response.data;
    },
    onSuccess: (purchasedIdea) => {
      onPurchaseIdea(purchasedIdea);
    }
  });
};

export const useOnUndoPurchase = (
  category: WishlistCategory | "purchased",
  wishlistId: WishlistRecord["id"]
) => {
  const queryClient = useQueryClient();
  const onIdeaUpdate = useOnIdeaUpdate(category === "purchased" ? "shared" : category, wishlistId);

  return (unpurchasedIdea: IdeaRecord) => {
    onIdeaUpdate(unpurchasedIdea);

    const queryKey = getPurchasedIdeasQueryKey();
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (purchasedIdeas: IdeaRecord[]) => {
        return purchasedIdeas.filter((purchasedIdea) => purchasedIdea.id !== unpurchasedIdea.id);
      });
    }
  };
};

export const useUndoPurchaseIdeaMutation = (
  category: WishlistCategory | "purchased",
  wishlistId: WishlistRecord["id"]
) => {
  const api = usePurchasesApi();
  const onUndoPurchase = useOnUndoPurchase(category, wishlistId);

  return useMutation({
    mutationFn: async ({ ideaId }: { ideaId: IdeaRecord["id"] }) => {
      const response = await api.destroy(ideaId);
      return response.data;
    },
    onSuccess: (unpurchasedIdea) => {
      onUndoPurchase(unpurchasedIdea);
    }
  });
};
