import { IdeaRecord } from "@/types/ideas";
import { WishlistCategory, WishlistRecord } from "@/types/wishlists";
import { Check as CheckIcon } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography
} from "@mui/material";
import React from "react";

import useAppContext from "@/hooks/useAppContext";
import { isPurchased, isPurchasedByUser } from "@/hooks/usePurchases";
import formatCurrency from "@/lib/formatCurrency";
import getRelativeDateTimeString from "@/lib/getRelativeDateTimeString";
import getStringAvatar from "@/lib/getStringAvatar";
import ManageDropdown from "./ManageDropdown";
import OpenLinkButton from "./OpenLinkButton";
import PurchaseButton from "./PurchaseButton";
import UndoPurchaseButton from "./UndoPurchaseButton";

type IdeaProps = {
  category: WishlistCategory | "purchased";
  wishlistId: WishlistRecord["id"];
  idea: IdeaRecord;
};

const Idea = ({ category, wishlistId, idea }: IdeaProps) => {
  const { auth } = useAppContext();
  const ideaCost = Number(idea.cost) || 0;

  const showPurchaseDescription = category === "purchased";
  const purchasedDescription = showPurchaseDescription
    ? `Purchased ${getRelativeDateTimeString(idea.purchasedAt)} from ${idea?.wishlist?.name} for ${idea?.wishlist?.user?.name} (@${idea?.wishlist?.user?.username})`
    : undefined;

  const showComment = (idea.comment?.length || 0) > 0;
  const showCost = ideaCost > 0;
  const showContent = showComment || showCost;

  const showPurchaseButton = category === "shared" && !isPurchased(idea);
  const showUndoPurchaseButton =
    (category === "shared" || category === "purchased") &&
    isPurchasedByUser(idea, auth?.currentUser);
  const showOpenLink = (idea.url?.length || 0) > 0;
  const showActions = showPurchaseButton || showUndoPurchaseButton || showOpenLink;

  const showIsPurchasedOverlay = category === "shared" && isPurchased(idea);
  const buttonColor = showIsPurchasedOverlay ? "secondary" : "primary";

  return (
    <Card sx={{ position: "relative" }}>
      {showIsPurchasedOverlay && (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
          bgcolor="success.light"
          color="success.dark"
          zIndex={1}
          sx={{ position: "absolute", opacity: "30%", inset: 0, pointerEvents: "none" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <CheckIcon />
            <Typography variant="h3">Purchased</Typography>
          </Stack>
        </Stack>
      )}
      <CardHeader
        avatar={<Avatar {...getStringAvatar(idea.name)} />}
        title={idea.name}
        titleTypographyProps={{ variant: "h4" }}
        subheader={purchasedDescription}
        action={
          category === "my" && (
            <ManageDropdown category={category} wishlistId={wishlistId} idea={idea} />
          )
        }
      />

      {showContent && (
        <CardContent>
          <Stack spacing={1.5}>
            {showComment && (
              <Typography variant="body1" color="text.secondary" fontStyle="italic">
                {idea.comment}
              </Typography>
            )}
            {showCost && (
              <Typography variant="body2" color="text.secondary">
                Estimated cost: {formatCurrency(ideaCost, idea.currency)}
              </Typography>
            )}
          </Stack>
        </CardContent>
      )}

      {showActions && (
        <CardActions>
          {showPurchaseButton && (
            <PurchaseButton
              category={category}
              wishlistId={wishlistId}
              idea={idea}
              color={buttonColor}
            />
          )}
          {showUndoPurchaseButton && (
            <UndoPurchaseButton
              category={category}
              wishlistId={wishlistId}
              idea={idea}
              color={buttonColor}
            />
          )}
          {showOpenLink && <OpenLinkButton idea={idea} color={buttonColor} />}
        </CardActions>
      )}
    </Card>
  );
};

export default Idea;
