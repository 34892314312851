import { useMutation, useQueryClient } from "@tanstack/react-query";

import hasQueryData from "@/lib/hasQueryData";
import type { IdeaRecord, IdeaRecordPartial } from "@/types/ideas";
import type { WishlistCategory, WishlistRecord } from "@/types/wishlists";
import useRequest from "./useRequest";
import useRestApi, { ApiError, getErrorMessages as getRestApiErrorMessages } from "./useRestApi";
import { getWishlistsQueryKey } from "./useWishlists";
export { useWishlistIdParam } from "./useWishlists";

export const getErrorMessages = (error: ApiError) => {
  return getRestApiErrorMessages<IdeaRecord>(error);
};

export const getIdeasQueryKey = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  return [...getWishlistsQueryKey(category, wishlistId), "ideas"];
};

export const sortIdeas = (ideas: IdeaRecord[]) => {
  return ideas.sort((ideaA, ideaB) => {
    if (ideaA.position === ideaB.position && ideaA.name === ideaB.name) {
      // If the positions and names are the same, sort by ID (newest first)
      return ideaA.id - ideaB.id;
    } else if (ideaA.position === ideaB.position) {
      // If the positions are the same, sort by name
      return ideaA.name.localeCompare(ideaB.name, undefined, { sensitivity: "base" });
    } else if (ideaA.position === null) {
      // If ideaA has no position, it should come before ideaB
      return -1;
    } else if (ideaB.position === null) {
      // If ideaB has no position, it should come before ideaA
      return 1;
    } else {
      // Otherwise, sort by position
      return ideaA.position - ideaB.position;
    }
  });
};

const useIdeasApi = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  return useRestApi<IdeaRecord>(`/api/${category}_wishlists/${wishlistId}/ideas`);
};

export const useIndexIdeasQuery = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useIdeasApi(category, wishlistId);
  return {
    queryKey: getIdeasQueryKey(category, wishlistId),
    queryFn: async () => {
      const response = await api.index();
      return response.data;
    }
  };
};

export const useOnIdeaCreate = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  const queryClient = useQueryClient();

  return (newIdea: IdeaRecord) => {
    if (hasQueryData(queryClient, getIdeasQueryKey(category, wishlistId))) {
      queryClient.setQueryData(
        getIdeasQueryKey(category, wishlistId),
        (currentIdeas: IdeaRecord[]) => {
          return sortIdeas([...currentIdeas, newIdea]);
        }
      );
    }
  };
};

export const useCreateIdeaMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useIdeasApi(category, wishlistId);
  const onIdeaCreate = useOnIdeaCreate(category, wishlistId);

  return useMutation({
    mutationFn: async (values: IdeaRecordPartial) => {
      const response = await api.create(values);
      return response.data;
    },
    onSuccess: (newIdea) => {
      onIdeaCreate(newIdea);
    }
  });
};

export const useOnIdeaUpdate = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  const queryClient = useQueryClient();
  const onIdeaDelete = useOnIdeaDelete(category, wishlistId);

  return (updatedIdea: IdeaRecord) => {
    if (updatedIdea.wishlistId !== wishlistId) {
      onIdeaDelete(updatedIdea.id);
    }

    const queryKey = getIdeasQueryKey(category, updatedIdea.wishlistId);
    if (hasQueryData(queryClient, queryKey)) {
      queryClient.setQueryData(queryKey, (currentIdeas: IdeaRecord[]) => {
        const otherIdeas = currentIdeas.filter((currentIdea) => currentIdea.id !== updatedIdea.id);
        return sortIdeas([...otherIdeas, updatedIdea]);
      });
    }
  };
};

export const useUpdateIdeaMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const api = useIdeasApi(category, wishlistId);
  const onIdeaUpdate = useOnIdeaUpdate(category, wishlistId);

  return useMutation({
    mutationFn: async ({ ideaId, data }: { ideaId: IdeaRecord["id"]; data: IdeaRecordPartial }) => {
      const response = await api.update(ideaId, data);
      return response.data;
    },
    onSuccess: (updatedIdea) => {
      onIdeaUpdate(updatedIdea);
    }
  });
};

export const useOnIdeaDelete = (category: WishlistCategory, wishlistId: WishlistRecord["id"]) => {
  const queryClient = useQueryClient();

  return (ideaId: IdeaRecord["id"]) => {
    if (hasQueryData(queryClient, getIdeasQueryKey(category, wishlistId))) {
      queryClient.setQueryData(
        getIdeasQueryKey(category, wishlistId),
        (currentIdeas: IdeaRecord[]): IdeaRecord[] => {
          return currentIdeas.filter((currentIdea) => currentIdea.id !== Number(ideaId));
        }
      );
    }
  };
};

export const useDeleteIdeaMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"],
  ideaId: IdeaRecord["id"]
) => {
  const api = useIdeasApi(category, wishlistId);
  const onIdeaDelete = useOnIdeaDelete(category, wishlistId);

  return useMutation({
    mutationFn: async () => {
      const response = await api.destroy(ideaId);
      return response.data;
    },
    onSuccess: () => {
      onIdeaDelete(ideaId);
    }
  });
};

type IdeaReorderUpdate = {
  id: IdeaRecord["id"];
  position: number;
};

export const useReorderIdeasMutation = (
  category: WishlistCategory,
  wishlistId: WishlistRecord["id"]
) => {
  const request = useRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (ideas: IdeaRecord[]) => {
      const reorderedIdeas = ideas.map((idea, index) => ({ ...idea, position: index }));
      queryClient.setQueryData(getIdeasQueryKey(category, wishlistId), () => reorderedIdeas);

      const reorderUpdates: IdeaReorderUpdate[] = [];
      ideas.forEach((idea, index) => {
        if (idea.position !== index) {
          reorderUpdates.push({ id: idea.id, position: index });
        }
      });

      const response = await request.patch<IdeaRecord[]>(
        `/api/${category}_wishlists/${wishlistId}/reorder_ideas`,
        {
          data: {
            ideas: reorderUpdates
          }
        }
      );
      return response.data;
    },
    onError: (_error, previousIdeas: IdeaRecord[]) => {
      // TODO: Show an error message
      queryClient.setQueryData(getIdeasQueryKey(category, wishlistId), () =>
        sortIdeas(previousIdeas)
      );
    }
  });
};
