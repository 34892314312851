import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";

import type { TribeRecord } from "@/types/tribes";

import type { ShareStatus, StatusChangeHandler, TribeIdsChangeHandler } from "./ShareModal";

export type ChooserProps = {
  tribes: TribeRecord[];
  status: ShareStatus;
  onStatusChange: StatusChangeHandler;
  tribeIds: TribeRecord["id"][];
  onTribeIdsChange: TribeIdsChangeHandler;
  errorMessage?: string;
};

const Chooser = ({
  tribes,
  status,
  onStatusChange,
  tribeIds,
  onTribeIdsChange,
  errorMessage
}: ChooserProps) => {
  return (
    <>
      <ToggleButtonGroup
        orientation="vertical"
        value={status}
        exclusive
        onChange={onStatusChange}
        fullWidth
      >
        <ToggleButton value="private">Private</ToggleButton>
        <ToggleButton value="shared">Shared</ToggleButton>
      </ToggleButtonGroup>
      {status === "shared" && (
        <>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Shared with:
          </Typography>
          <ToggleButtonGroup
            orientation="vertical"
            value={tribeIds}
            onChange={onTribeIdsChange}
            sx={{ width: "100%" }}
          >
            {(tribes || []).map((tribe) => (
              <ToggleButton key={tribe.id} value={tribe.id}>
                {tribe.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      )}
      {errorMessage && (
        <Typography color="error" variant="body1" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default Chooser;
