import { Stack, Typography } from "@mui/material";
import React from "react";

import FriendsSvg from "@/svg/FriendsSvg";

const BlankSlate = () => {
  return (
    <Stack alignItems="center" spacing={4} mt={5}>
      <FriendsSvg sx={{ width: "20rem" }} />
      <Stack textAlign="center" spacing={1}>
        <Typography variant="h2">No purchased ideas found</Typography>
        <Typography variant="subtitle1">Purchase some ideas to see them here.</Typography>
      </Stack>
    </Stack>
  );
};

export default BlankSlate;
