import { Stack } from "@mui/material";
import React from "react";

import Idea from "@/components/Idea";
import WishlistTabs from "@/components/WishlistTabs";
import type { IdeaRecord } from "@/types/ideas";
import BlankSlate from "./BlankSlate";

type PurchasedIdeaListProps = {
  ideas: IdeaRecord[];
};

const PurchasedIdeaList = ({ ideas }: PurchasedIdeaListProps) => {
  const hasIdeas = ideas.length > 0;

  return (
    <>
      <WishlistTabs category="purchased" />
      {hasIdeas && (
        <Stack spacing={3}>
          {ideas.map((idea) => (
            <Idea category="purchased" wishlistId={idea.wishlistId} key={idea.id} idea={idea} />
          ))}
        </Stack>
      )}
      {!hasIdeas && <BlankSlate />}
    </>
  );
};

export default PurchasedIdeaList;
