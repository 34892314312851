import { useQuery } from "@tanstack/react-query";
import React from "react";

import PurchasedIdeaList from "@/components/PurchasedIdeaList";
import { useIndexPurchasedIdeasQuery } from "@/hooks/usePurchases";

const PurchasedIdeas = () => {
  const { data: ideas } = useQuery(useIndexPurchasedIdeasQuery());
  return <PurchasedIdeaList ideas={ideas || []} />;
};

export default PurchasedIdeas;
